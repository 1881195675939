import React, { Component } from "react";
import photo from "./rm_logo.png";
import h3logo from "./engg.png";
import "./style.css";
const Box3 = () => {
  return (
    <div id="app1">
      <img className="photo1" src={h3logo} alt="Logo" />
    </div>
  );
};
export default Box3;
