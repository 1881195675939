import React, { Component } from "react";
import "./style.css";
const Box = () => {
  return (
    <div id="app">
      <h6 align="center"> We Will Be Back Soon</h6>

      <h5>Great Things Take Time</h5>
    </div>
  );
};
export default Box;
