import React, { Component } from "react";
import "./style.css";
const NavBar = () => {
  return (
    <nav class="navbar navbar-light bg-light">
      <a class="navbar-brand" href="#">
        AKSC
      </a>
    </nav>
  );
};
export default NavBar;
