import React, { Component } from "react";
import NavBar from "./bar";
import Box from "./box";
import Box1 from "./box1";
import Box2 from "./box2";
import Box3 from "./box3";

import "./style.css";
class App extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Box />
        <div id="leftbox">
          <Box1 />
        </div>
        <div id="middlebox">
          <Box2 />
        </div>
        <div id="rightbox">
          <Box3 />
        </div>
      </React.Fragment>
    );
  }
}

export default App;
